import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    error: null,
    loading: false,
    priceMarketlist: {},
    CurrencyList: {},
    walletList: {},
    depositModel: false,
    WithdrawModel: false,
};

// Reducer 
const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        currency: (state, action) => {
            state.CurrencyList = action.payload;
            state.loading = false;
        },
        userWalletList: (state, action) => {
            state.walletList = action.payload;
            state.loading = false;
            state.error = null;

        },
        priceConversion: (state, action) => {
            state.priceMarketlist = action.payload;
            state.error = action.payload;
            state.loading = false;
        },
        clearWallet: (state) => {
            state.loading = false;
            state.error = null;
            state.priceMarketlist = null;
            state.CurrencyList = null;
            state.walletList = null;
        },
        updateDepositModel: (state,action) => {
            state.depositModel = action.payload;
        },
        updateWithdrawModel: (state, action) => {
            state.WithdrawModel = action.payload;
        },

        updateUserFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        deleteUserStart: (state) => {
            state.loading = true;
        },
        deleteUserSuccess: (state) => {
            state.currentUser = null;
            state.loading = false;
            state.error = null;
        },
        deleteUserFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        signOutUserStart: (state) => {
            state.loading = true;
        },
        signOutUserSuccess: (state, action) => {
            state.currentUser = null;
            state.loading = false;
            state.error = null;
        },
        signOutUserFailure: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },

    },
});

export const {
    currency,
    userWalletList,
    priceConversion,
    clearWallet,
    updateWithdrawModel,
    updateDepositModel,
    deleteUserStart,
    deleteUserSuccess,
    deleteUserFailure,
    signOutUserStart,
    signOutUserSuccess,
    signOutUserFailure,

} = walletSlice.actions;

export default walletSlice.reducer;