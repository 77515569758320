import React from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


const ConditionRoute = ({ type, children, ...rest }) => {
   const location = useLocation();
   const { login, token, siteSetting } = useSelector(state => state.user);
   if (location?.pathname == "/maintenanceMode" && siteSetting?.maintenanceMode) return children;
   if (siteSetting?.maintenanceMode && location?.pathname != "/maintenanceMode") return <Navigate to='/maintenanceMode' />

   else if (type == 'auth' && login && token) {
      return <Navigate to='/dashboard' />
   } else if (type == 'private' && !login && !token) {
      return <Navigate to='/login' />
   } else if (!siteSetting?.maintenanceMode && location?.pathname == "/maintenanceMode") {
      return <Navigate to='/login' />
   }

   return children;
};

export default ConditionRoute;