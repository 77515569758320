import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function toastAlert(errorType, message, id=Math.floor(Math.random() * 100), position = 'top-center' /*TOP_RIGHT, TOP_CENTER*/) {

    if (errorType === 'error') {

        toast.error(message.replaceAll('_', ' '), {
            autoClose: 2000,
            toastId: id,
            position: "top-center",
        });

    } else if (errorType === 'success') {
        toast.success(message.replaceAll('_', ' '), {
            autoClose: 2000,
            toastId: id,
            position: "top-center",
        });
    }
}

export const textCopy = (text) => {
    navigator.clipboard.writeText(text)
    toastAlert('success', 'Copied!')
}