// import config
import axios, { setAuthorization, removeAuthorization, handleResp } from '../config/axios'
import config from '../config';
import { removeAuthToken, setAuthToken } from '../lib/localStorage';
import { toastAlert } from '../lib/toastAlert';



// import constant
import {

    // UPDATE_USER_ACCOUNT,
    RESET_NOTICE,
    SET_UNREAD_NOTICE

} from '../constant';

//redux 
import { updateSiteSetting, setAccountData, clearUser,setMaintenanceMode } from "../redux/user/userSlice";
import { clearWallet } from "../redux/wallet/walletSlice";

export const createUser = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/user`,
            data
        });

        if (respData?.data?.status === 'OTP') {
            return {
                status: 'OTP',
                loading: false,
                message: respData.data.message,
            }
        }

        return {
            status: 'success',
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: 'failed',
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors
        }
    }
}

export const login = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/login`,
            data
        });
        // console.log(respData?.data?.token,'respData?.data?.token')
        // if(respData?.data?.status === 'OTP') {
        //     return {
        //         status: 'OTP',
        //         loading: false,
        //         message: respData.data.message,
        //     }
        // }

        //  setAuthorization(respData?.data?.token)
        //  decodeJwt(respData?.data?.token, dispatch)
        //  setAuthToken(respData?.data?.token)
        // dispatch(setAccountData(respData?.data?.result))
        //  getAssetData(dispatch)
        // getRefLevList(dispatch)
        if (respData.data.status == "TWO_FA") {
            return {
                status: "success",
                type: "TWO_FA",
                message: respData?.data?.message,
            };
        }
        if (respData.data.status == "EMAIL_OTP") {
            return {
                status: "success",
                type: "EMAIL_OTP",
                message: respData?.data?.message,
            };
        }
        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            message: respData.data.message,
            userSetting: respData.data.userSetting,
            data: respData.data
        }
        // }
    }
    catch (err) {
        handleResp(err, 'error')

        try {
            return {
                status: "failed",
                loading: false,
                message: err.response.data.message,
                error: err.response.data.errors
            }
        } catch (err) {
            handleResp(err, 'error')
            return {
                status: "failed",
                loading: false,
            }
        }
    }
}

export const getGeoInfoData = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.getGeoInfo}`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}



export const logout = async (navigate, dispatch) => {
    dispatch(clearUser())
    dispatch(clearWallet())
    // toastAlert('success', 'Logout Successfully!', 'logout')
    navigate('/login')
    // setTimeout(() => { navigate('/login') }, 1000)
}

export const emailVerify = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/confirm-mail`,
            data
        });

        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message
        }
    }
}

export const forgotPassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/forgetPassword`,
            data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message,
            errors: err.response.data.errors
        }
    }
}



export const resetPasswordVerification = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/resetconfirmMail`,
            data
        });
        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message
        }
    }
}

export const getCms = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getCms`,
            data
        });
        return {
            status: true,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, "error");
        return {
            status: false,
            loading: false,
        };
    }
};

export const resetPassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/resetPassword`,
            data
        });

        return {
            status: true,
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: false,
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        }
    }
}

export const viewUserProfile = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/userProfile`
        });
        dispatch(setAccountData(respData.data.result))
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
        }
    }
}

export const updateProfile = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/user/updateProfile`,
            data
        });

        dispatch(setAccountData(respData.data.result))

        return {
            status: "success",
            loading: false,
            message: respData.data.message
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            errors: err?.response?.data?.errors
        }
    }
}
export const updateProfilePic = async (data) => {
    try {
      let respData = await axios({
        method: "patch",
        url: `/api/userProfile`,
        data,
      });
      return {
        status: true,
        message: respData.data.message,
      };
    } catch (err) {
      return {
        status: false,
        message: err.response.data.message,
        error: err.response.data.errors,
      };
    }
  };
export const getLoginHistory = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/loginHistory`,
            data,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message
        }
    }
}

export const changePasswordAPI = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/changePassword`,
            data
        });

        return {
            status: true,
            loading: false,
            message: respData.data.message,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: false,
            loading: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        }
    }
}

export const getAllFaq = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/faq`
        });

        return {
            status: true,
            result: respData.data.result,
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: false,
            loading: false,
            message: err.response.data.message
        }
    }
}


export const getHomePage = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/getHomepage`,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message
        }
    }
}

export const unReadNotice = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/unread-notice`
        });

        dispatch({
            "type": SET_UNREAD_NOTICE,
            "notice": respData.data.result.NoticeData,
            "notifiyCount": respData.data.result.notifiyCount,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message
        }
    }
}

export const clearNotification = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `/api/user/unread-notice`
        });

        dispatch({
            "type": SET_UNREAD_NOTICE,
            "notice": respData.data.result.NoticeData,
            "notifiyCount": respData.data.result.notifiyCount,
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err.response.data.message
        }
    }
}

export const FetchunReadNotice = async (dispatch, data) => {
    try {
        dispatch({
            type: SET_UNREAD_NOTICE,
            notice: data.NoticeData,
            notifiyCount: data.notifiyCount,
        });
    } catch (err) { }
};

export const getServerTime = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/user/serverTime`
        });

        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message
        }
    }
}

export const getSiteSetting = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/siteSetting`
        });
        dispatch(updateSiteSetting(respData.data.result))
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message
        }
    }
}


export const get2faCode = async () => {
    try {
        let respData = await axios({
            method: "get",
            url: `/api/2fa`,
        });
        return {
            status: true,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message,
        };
    }
};

export const enable2faCode = async (data, dispatch) => {
    try {
        let respData = await axios({
            method: "put",
            url: `/api/2fa`,
            data,
        });

        dispatch(setAccountData(respData.data.userInfo));

        return {
            status: true,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const disable2faCode = async (data, dispatch) => {
    try {
        let respData = await axios({
            method: "patch",
            url: `/api/2fa`,
            data,
        });

        dispatch(setAccountData(respData.data.userInfo));

        return {
            status: true,
            message: respData.data.message,
            result: respData.data.result,
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const sendVerificationCode = async (data = {}) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/sendVerificationCode`,
            data,
        });
        return {
            status: true,
            message: respData.data.message,
        };
    } catch (err) {
        return {
            status: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};
export const changeEmail = async (data, dispatch) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/emailChange`,
            data,
        });
        if (respData.data?.result)
            dispatch(setAccountData(respData.data.result));
        return {
            status: true,
            message: respData.data.message,
            verfication: respData.data.verfication,
        };
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: false,
            message: err.response.data.message,
            error: err.response.data.errors,
        };
    }
};

export const getNotificationHistory = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/notificationHistory`,
            data,
        });
        return {
            status: true,
            result: respData.data.result,
            totalCount: respData.data.totalCount,
        };
    } catch (err) {
        return {
            status: false,
        };
    }
};

export const updateNotificationRead = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/updateNotificationRead`,
            data,
        });
        return {
            status: true,
            result: respData.data.success,
        };
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: false,
        };
    }
};

export const updateAllRead = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/updateAllRead`,
            data,
        });
        return {
            status: true,
            result: respData.data.success,
        };
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: false,
        };
    }
};


export const getPortfolioChart = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/getPortfolioChart`,
            data,
        });
        return {
            status: true,
            result: respData.data.result,
        };
    } catch (err) {
        handleResp(err, 'error')
        return {
            status: false,
        };
    }
};

export const contactus = async (data) => {
    try {
        let respData = await axios({
            method: "post",
            url: `/api/contactus`,
            data,
        });
        return {
            status: true,
            result: respData.data.result,
            info: respData.data.message,
        };
    } catch (err) {
        return {
            status: false,
            info: err.response.data.message,
        };
    }
};
export const getMaintenanceMode = async (dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/getMaintenanceMode`
        });
        dispatch(setMaintenanceMode(respData.data.result))
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    }
    catch (err) {
        handleResp(err, 'error')
        return {
            status: "failed",
            loading: false,
            message: err?.response?.data?.message
        }
    }

}